export class QueryParamsModel {
	// fields
	filter: any;
	sort_order: string; // asc || desc
	sort_field: string;
	page_number: number;
	page_size: number;
	bill_id: number;

	// constructor overrides
	constructor(_filter: any,
		_sortOrder: string = 'desc',
		_sortField: string = 'id',
		_pageNumber: number = 0,
		_pageSize: number = 100,
		_billid: number = 0) {
		this.filter = _filter;
		this.bill_id = _billid;
		this.sort_order = _sortOrder;
		this.sort_field = _sortField;
		this.page_number = _pageNumber;
		this.page_size = _pageSize;
	}
}

export class QueryDrillsParamsModel {
	country_id: number;
	regional_manager: number;
	manager_id: number;
	team_id: number;
	constructor(_c_id: number, _r_m_id: number, _m_id: number, _t_id: number) {
		this.country_id = _c_id;
		this.regional_manager = _r_m_id;
		this.manager_id = _m_id;
		this.team_id = _t_id;
	}
}

export class LiveRoutingParamsModel {
	country_id: number;
	regional_manager: number;
	manager_id: number;
	team_id: number;
	constructor(_c_id: number, _r_m_id: number, _m_id: number, _t_id: number) {
		this.country_id = _c_id;
		this.regional_manager = _r_m_id;
		this.manager_id = _m_id;
		this.team_id = _t_id;
	}

}

export class LiveRoutingParams {
	country_id: number;
	selected_regionalmanager: any;
	constructor(_c_id: number) {
		this.country_id = _c_id;
	}

}
