export class QueryResultsModel {
  // fields
  data: dataModel;
  items: any[];
  total_count: number;
  errorMessage: string;
  message: string;
  permissions: any[];

  constructor(_items: any[] = [], _errorMessage: string = "") {
    this.items = _items;
    this.total_count = _items.length;
  }
}

export class dataModel {
  items: any[];
  total_count: number;
}
export class QueryDrillsModel {
  data: drills;
  status: boolean;
  message: string;
}
export class drills {
  regionList: SelectlistModel[];
  regional_manager: SelectlistModel[];
  managerList: SelectlistModel[];
  teamList: SelectlistModel[];
  salesRepList: SelectlistModel[];
  statusList: SelectlistModel[];
  priorityList: SelectlistModel[];
  params: AllowedParams;
  is_adminloggedin: boolean;
  selected_region: number;
  selected_regional_manager: number;
  selected_manager: number;
}
export class AllowedParams {
  Create: boolean;
  Edit: boolean;
  Delete: boolean;
  List: boolean;
}
export class SelectlistModel {
  id: number;
  name: string;
}
